body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app__container {
  height: auto;
  min-height: 100vh; }

.App__Header {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }

.App__Header {
  background-color: #fff;
  padding: 30px 0; }

.APP__Content {
  margin-top: 20px; }

.ant-btn {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.ant-dropdown-menu-item .ant-btn,
.ant-dropdown-menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

