@import "~antd/dist/antd.css";

.app__container {
  height: auto;
  min-height: 100vh;
}

%box-shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.App__Header {
  background-color: #fff;
  padding: 30px 0;
  @extend %box-shadow;
}

.APP__Content {
  margin-top: 20px;
}

.APP_Container {
  // @extend %box-shadow;
  // display: flex;
}

.ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ant-dropdown-menu-item .ant-btn,
.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
}

// Personal
